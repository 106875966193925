define('ornio-nav-app/helpers/link-status', ['exports', 'ember'], function (exports, _ember) {
    exports.linkStatus = linkStatus;

    function linkStatus(params /*, hash*/) {
        var current = params[0];
        var child = params[1];

        if (current == child) {
            return 'active';
        }

        return true;
    }

    exports['default'] = _ember['default'].Helper.helper(linkStatus);
});