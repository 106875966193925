define('ember-cli-jsonapi-pagination/mixins/controllers/jsonapi-pagination', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    size: 15,
    number: 1,
    queryParams: ['size', 'number'],

    actions: {
      setCurrentPage: function setCurrentPage(currentPage) {
        this.set('number', currentPage);
      },

      setCurrentSize: function setCurrentSize(currentSize) {
        this.set('size', currentSize);
      }
    }
  });
});