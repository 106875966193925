define('ornio-app-updated/components/ornio/app-updated', ['exports', 'ornio-app-updated/templates/components/ornio/app-updated', 'ember-get-config'], function (exports, _appUpdated, _emberGetConfig) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _appUpdated.default,
        tagName: "div",
        classNames: ['ornio-app-updated'],
        interval: 120000,
        message: "Application has been updated from version {{oldVersion}} to {{newVersion}}.",
        reloadButtonText: "Reload",
        showReloadButton: true,
        showMessage: Ember.computed.or('hasBeenUpdated', 'isTestMode'),
        hasBeenUpdated: false,
        isTestMode: false,

        init: function init() {
            this._super.apply(this, arguments);

            // If appUpdated not set skip version check
            if (!_emberGetConfig.default.appUpdated) {
                return;
            }

            // Check and match versions
            Ember.run.scheduleOnce('afterRender', this, 'checkAndMatchVersion');
        },

        /**
         * Get generated file url.
         */
        url: Ember.computed(function () {
            var options = _emberGetConfig.default.appUpdated;
            var baseUrl = _emberGetConfig.default.rootURL || _emberGetConfig.default.baseURL;

            if (baseUrl === "/") {
                return '/' + options.fileName;
            }

            return baseUrl + options.fileName;
        }).readOnly(),

        /**
         * Check and match config version with file version.
         * Schedule next run.
         */
        checkAndMatchVersion: function checkAndMatchVersion() {
            var _this = this;

            var interval = Ember.get(this, 'interval');

            // Check if Ember.$ exists
            if (!Ember.$) {
                return;
            }

            // Fetch version file
            Ember.$.ajax(Ember.get(this, 'url'), { cache: false }).then(function (payload) {

                var fileVersion = Ember.get(payload, 'version'); // New version
                var configVersion = _emberGetConfig.default.appUpdated.version; // Old version

                if (fileVersion && fileVersion !== configVersion) {
                    var message = Ember.get(_this, 'message').replace("{{oldVersion}}", configVersion).replace("{{newVersion}}", fileVersion);

                    Ember.set(_this, 'message', message);
                    Ember.set(_this, 'hasBeenUpdated', true);
                }
            }).always(function () {
                // Schedule next run
                Ember.set(_this, '_later', Ember.run.later(_this, 'checkAndMatchVersion', interval));
            });
        },


        /**
         * On component destroy cancel run loop
         */
        willDestroy: function willDestroy() {
            this._super.apply(this, arguments);

            // Cancel run loop
            Ember.run.cancel(Ember.get(this, '_later'));
        },

        actions: {
            /**
             * Reload action
             */
            reload: function reload() {
                if (typeof window !== 'undefined' && window.location) {
                    window.location.reload();
                }
            },


            /**
             * Close action
             */
            close: function close() {
                this.set('hasBeenUpdated', false);
            }
        }
    });
});