define('ornio-nav-app/components/bs/layout-outlet', ['exports', 'ember', 'ornio-nav-app/templates/components/bs/layout-outlet', 'ember-computed', 'ember-service/inject'], function (exports, _ember, _ornioNavAppTemplatesComponentsBsLayoutOutlet, _emberComputed, _emberServiceInject) {
    exports['default'] = _ember['default'].Component.extend({
        layout: _ornioNavAppTemplatesComponentsBsLayoutOutlet['default'],
        menu: (0, _emberServiceInject['default'])('extend-menu'),
        classNames: ['bs-layout'],
        left: true,
        open: true,
        right: _emberComputed['default'].equal('left', false),
        classNameBindings: ['left:left', 'right:right', 'menu.menu.hidden:hidden', 'menu.menu.open:open']
    });
});