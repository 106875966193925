define('ember-cli-simditor/components/simditor-editor', ['exports', 'ember-cli-simditor/templates/components/simditor-editor'], function (exports, _simditorEditor) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        run = Ember.run,
        merge = Ember.merge,
        getProperties = Ember.getProperties;
    exports.default = Ember.Component.extend({
        layout: _simditorEditor.default,
        upload: false,
        tabIndent: true,
        toolbar: true,
        toolbarFloat: true,
        toolbarFloatOffset: 0,
        toolbarHidden: false,
        pasteImage: false,
        cleanPaste: false,
        params: {},
        defaultImage: 'assets/passed.png',
        placeholder: 'Type something here',
        locale: 'en-US',
        _editor: null,
        name: 'content',

        didReceiveAttrs: function didReceiveAttrs() {
            var editor = this.get('_editor');
            var value = this.attrs.value.value;
            var propName = this.get('name');
            if (editor && value) {
                if (editor.getValue() !== get(value, propName)) {
                    editor.setValue(get(value, propName));
                }
            }
        },
        didInsertElement: function didInsertElement() {
            var _this = this;

            Simditor.locale = this.get('locale');
            var options = merge({
                textarea: this.$('textarea')
            }, getProperties(this, 'upload', 'tabIndent', 'toolbar', 'toolbarFloat', 'toolbarFloatOffset', 'toolbarHidden', 'pasteImage', 'cleanPaste', 'defaultImage', 'placeholder'));

            var editor = new Simditor(options);
            var value = get(this, 'value');
            if (value) {
                var content = get(value, this.get('name'));
                editor.setValue(content);
            }

            this.set('_editor', editor);

            editor.on('valuechanged', function (e) {
                if (typeof _this.attrs.onValuechanged === 'function') {
                    _this.attrs.onValuechanged(e, editor);
                }

                if (typeof _this.attrs.update === 'function') {
                    _this.attrs.update(editor.getValue());
                }
            });

            editor.on('selectionchanged', function (e) {
                if (typeof _this.attrs.onSelectionchanged === 'function') {
                    _this.attrs.onSelectionchanged(e, editor);
                }
            });

            editor.on('decorate', function (e, el) {
                if (typeof _this.attrs.onDecorate === 'function') {
                    _this.attrs.onDecorate(e, el, editor);
                }
            });

            editor.on('undecorate', function (e, el) {
                if (typeof _this.attrs.onUndecorate === 'function') {
                    _this.attrs.onUndecorate(e, el, editor);
                }
            });

            editor.on('pasting', function (e, pasteContent) {
                if (typeof _this.attrs.onPasting === 'function') {
                    return _this.attrs.onPasting(e, pasteContent, editor);
                }
            });

            editor.on('focus', function (e) {
                if (typeof _this.attrs.onFocus === 'function') {
                    _this.attrs.onFocus(e, editor);
                }
            });

            editor.on('blur', function (e) {
                if (typeof _this.attrs.onBlur === 'function') {
                    _this.attrs.onBlur(e, editor);
                }
            });

            editor.on('destroy', function (e) {
                if (typeof _this.attrs.onDestroy === 'function') {
                    _this.attrs.onDestroy(e, editor);
                }
            });

            run.scheduleOnce('afterRender', this, function (editor) {
                if (this.attrs.editor) {
                    this.attrs.editor.update(editor);
                }
            }, editor);
        },
        willDestroy: function willDestroy() {
            var editor = this.get('editor');
            if (editor) {
                editor.destroy();
            }
            this._super.apply(this, arguments);
        }
    });
});