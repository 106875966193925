define('ornio-auth-engine/auth/default-schema', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var schema = exports.schema = {
        isServiceFactory: true
    };

    exports.default = Ember.Object.extend(schema);
});