define('ornio-nav-app/components/bs/insert-subnav', ['exports', 'ember', 'ornio-nav-app/templates/components/bs/insert-subnav', 'ember-service/inject', 'ember-metal/get', 'ember-string'], function (exports, _ember, _ornioNavAppTemplatesComponentsBsInsertSubnav, _emberServiceInject, _emberMetalGet, _emberString) {
    exports['default'] = _ember['default'].Component.extend({
        layout: _ornioNavAppTemplatesComponentsBsInsertSubnav['default'],
        router: (0, _emberServiceInject['default'])("-routing"),
        menu: (0, _emberServiceInject['default'])('extend-menu'),
        hide: true,
        classNameBindings: ['hide:d-none'],

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            // get menu service
            var menu = (0, _emberMetalGet['default'])(this, 'menu');

            // get SideNav from Menu Service
            var sideNav = menu.get('menu');

            //Extend Menu with child menu

            var cleanContent = _emberString['default'].htmlSafe(this.$()[0].innerHTML);

            //Current URL with Extend Menu
            var url = (0, _emberMetalGet['default'])(this, 'router.currentRouteName');

            if (!menu.get('url')) {
                sideNav.set('extendMenu', cleanContent);
                sideNav.publicActions.showSubNavigation('appends');
                menu.set('url', url);
            }
        },
        didRender: function didRender() {
            this._super.apply(this, arguments);

            var _this = this;
            // get menu service
            var menu = (0, _emberMetalGet['default'])(_this, 'menu');

            if (menu.get('url')) {
                // get SideNav from Menu Service
                var sideNav = menu.get('menu');

                //Extend Menu with child menu
                var cleanContent = _emberString['default'].htmlSafe(this.$()[0].innerHTML);

                //Current URL with Extend Menu
                sideNav.set('extendMenu', cleanContent);
            }

            var url = (0, _emberMetalGet['default'])(this, 'router.currentRouteName');
            menu.set('url', url);
        }
    });
});