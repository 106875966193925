define('ornio-nav-app/components/bs/side-nav', ['exports', 'ember', 'ornio-nav-app/templates/components/bs/side-nav', 'ember-metal/get', 'ember-metal/set', 'ember-metal/observer', 'ember-computed', 'ember-service/inject', 'ember-string'], function (exports, _ember, _ornioNavAppTemplatesComponentsBsSideNav, _emberMetalGet, _emberMetalSet, _emberMetalObserver, _emberComputed, _emberServiceInject, _emberString) {
    exports['default'] = _ember['default'].Component.extend({
        router: (0, _emberServiceInject['default'])("-routing"),
        layout: _ornioNavAppTemplatesComponentsBsSideNav['default'],
        media: (0, _emberServiceInject['default'])('media'),
        menu: (0, _emberServiceInject['default'])('extend-menu'),
        classNames: ['bs-container'],
        increment: 0,
        name: 'navigation',
        current: 'navigation',
        left: true,
        open: true,
        hidden: false,
        extendMenu: '',
        right: _emberComputed['default'].equal('left', false),
        classNameBindings: ['right:right'],
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.publicActions = {
                showSubNavigation: function showSubNavigation() {
                    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
                        args[_key] = arguments[_key];
                    }

                    return _this.send.apply(_this, ['showSubNavigation'].concat(args));
                },
                closeSubNavigation: function closeSubNavigation() {
                    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
                        args[_key2] = arguments[_key2];
                    }

                    return _this.send.apply(_this, ['closeSubNavigation'].concat(args));
                },
                hideNavigation: function hideNavigation() {
                    for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
                        args[_key3] = arguments[_key3];
                    }

                    return _this.send.apply(_this, ['hideNavigation'].concat(args));
                }
            };

            var media = (0, _emberMetalGet['default'])(this, 'media');
            var menu = (0, _emberMetalGet['default'])(this, 'menu');

            if (!(media.get('isXl') || media.get('isLg'))) {
                (0, _emberMetalSet['default'])(this, 'open', false);
                (0, _emberMetalSet['default'])(menu, 'open', false);
            }

            this.checkRoutes();
        },
        checkRoutes: function checkRoutes() {
            var menu = (0, _emberMetalGet['default'])(this, 'menu');
            var current_url = (0, _emberMetalGet['default'])(this, 'router.currentRouteName');
            var prev_url = (0, _emberMetalGet['default'])(menu, 'url');

            var menu_on = false;

            var BreakException = {};

            // if( current_url )
            if (!current_url || !prev_url) {
                return false;
            }

            var current_array = current_url.split('.');
            var prev_url_array = prev_url.split('.');

            if (current_array.length == prev_url_array.length) {

                var temp = prev_url_array.slice(0, -1);

                try {
                    temp.forEach(function (item, index, enumerable) {

                        if (item === current_array[index]) {
                            menu_on = true;
                        } else {
                            menu_on = false;
                            throw BreakException;
                        }
                    });
                } catch (e) {
                    //_this.closeSubNavigations();

                    if (e !== BreakException) throw e;
                }
            } else {
                menu_on = false;
            }

            if (!menu_on) {
                this.destroyInjection();
            }
        },
        currentRouteNameChanged: (0, _emberMetalObserver['default'])('router.currentRouteName', function () {
            this.checkRoutes();
        }),

        publicAPI: (0, _emberComputed['default'])('_publicAPI', function () {
            return this.get('_publicAPI');
        }),

        destroyInjection: function destroyInjection() {
            var menu = (0, _emberMetalGet['default'])(this, 'menu');
            (0, _emberMetalSet['default'])(this, 'extendMenu', '');
            (0, _emberMetalSet['default'])(this, 'appends', false);
            (0, _emberMetalSet['default'])(menu, 'url', '');

            (0, _emberMetalSet['default'])(this, 'name', 'navigation');
            (0, _emberMetalSet['default'])(this, 'current', 'navigation');
            (0, _emberMetalSet['default'])(this, 'open', true);
            (0, _emberMetalSet['default'])(menu, 'open', true);
        },

        _publicAPI: (0, _emberComputed['default'])('menu.{open,url}', 'router.currentRouteName', 'open', 'current', 'name', function () {
            var guid = this.elementId;
            var menu = (0, _emberMetalGet['default'])(this, 'menu');
            menu.setMenu(this);
            return {
                uniqueId: guid,
                closeSubNavigation: this.get('closeSubNavigation'),
                showSubNavigation: this.get('showSubNavigation'),
                hideNavigation: this.get('hideNavigation'),
                hidden: this.get('hidden'),
                actions: this.get('publicActions'),
                name: this.get('name'),
                getName: this.get('getName'),
                current: this.get('current'),
                left: this.get('left'),
                right: this.get('right'),
                open: this.get('open'),
                extendMenu: this.get('extendMenu')
            };
        }),
        closeSubNavigations: function closeSubNavigations() {
            var menu = (0, _emberMetalGet['default'])(this, 'menu');

            (0, _emberMetalSet['default'])(this, 'current', '');
            (0, _emberMetalSet['default'])(this, 'name', '');
            (0, _emberMetalSet['default'])(this, 'open', false);
            (0, _emberMetalSet['default'])(menu, 'open', false);
        },
        hideNavigation: function hideNavigation(param) {
            var menu = (0, _emberMetalGet['default'])(this, 'menu');
            (0, _emberMetalSet['default'])(this, 'hidden', param);
            (0, _emberMetalSet['default'])(menu, 'hidden', param);
        },
        actions: {
            destroyInjection: function destroyInjection() {
                this.destroyInjection();
            },
            closeSubNavigation: function closeSubNavigation() {
                this.closeSubNavigations();
            },
            hideNavigation: function hideNavigation(param) {
                this.hideNavigation(param);
            },
            showSubNavigation: function showSubNavigation(child) {
                var menu = (0, _emberMetalGet['default'])(this, 'menu');
                var name = (0, _emberMetalGet['default'])(this, 'name');

                if (child == name) {
                    this.closeSubNavigations();
                    if (!(0, _emberMetalGet['default'])(this, 'extendMenu')) {
                        (0, _emberMetalSet['default'])(this, 'name', 'navigation');
                        (0, _emberMetalSet['default'])(this, 'current', 'navigation');
                        (0, _emberMetalSet['default'])(this, 'open', true);
                        (0, _emberMetalSet['default'])(menu, 'open', true);
                    }
                } else {
                    (0, _emberMetalSet['default'])(this, 'name', child);
                    (0, _emberMetalSet['default'])(this, 'current', child);
                    (0, _emberMetalSet['default'])(this, 'open', true);
                    (0, _emberMetalSet['default'])(menu, 'open', true);
                }
            }
        }
    });
});