define('ember-cli-jsonapi-pagination/mixins/routes/jsonapi-pagination', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    queryParams: {
      size: {
        refreshModel: true
      },
      number: {
        refreshModel: true
      }
    },

    queryPaginated: function queryPaginated(modelName, params) {
      this.resolveParamsForPagination(params);
      return this.store.query(modelName, params);
    },

    resolveParamsForPagination: function resolveParamsForPagination(params) {
      params.page = { number: params.number, size: params.size };

      delete params.number;
      delete params.size;
    }
  });
});