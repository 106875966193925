define('ember-cli-jsonapi-pagination/components/paginate-collection', ['exports', 'ember'], function (exports, _ember) {

  var PageObject = _ember['default'].Object;

  exports['default'] = _ember['default'].Component.extend({
    outerWindow: 3,

    setup: _ember['default'].on('init', function () {
      this.set('currentPageToBeSet', this.get('currentPage'));
    }),

    shouldDisplayPaginator: _ember['default'].computed('totalPages', function () {
      return Number(this.get('totalPages')) > 1;
    }),

    hasPreviousPage: _ember['default'].computed('currentPage', 'totalPages', function () {
      var totalPages = this.get('totalPages');
      var currentPage = this.get('currentPage');
      return Number(currentPage) !== 1 && Number(totalPages) > 1;
    }),

    hasNextPage: _ember['default'].computed('currentPage', 'totalPages', function () {
      var totalPages = this.get('totalPages');
      var currentPage = this.get('currentPage');
      return Number(currentPage) !== Number(totalPages) && Number(totalPages) > 1;
    }),

    pageObjects: _ember['default'].computed('totalPages', 'currentPage', function () {
      var totalPages = this.get('totalPages');
      var currentPage = this.get('currentPage');
      var pages = _ember['default'].A([]);
      var outerWindow = Number(this.get('outerWindow'));
      var leftDotsDisplayed = false;
      var rightDotsDisplayed = false;
      var pageStartAtValue = 1;
      var pageEndAtValue = totalPages;
      var pageValues = extractPageValues(outerWindow, currentPage, pageStartAtValue, pageEndAtValue);

      pageValues.forEach(function (page) {
        var shouldDisplay = undefined,
            displayAsDots = undefined,
            isCurrentPage = undefined;

        if (page === currentPage) {
          isCurrentPage = true;
        } else {
          isCurrentPage = false;
        }

        if (shouldConsiderOuterWindowSettings(totalPages, outerWindow)) {
          if (isCurrentPage) {
            shouldDisplay = true;
            displayAsDots = false;
          } else if (isWithinOuterWindowLimit(outerWindow, totalPages, page)) {
            shouldDisplay = true;
            displayAsDots = false;
          } else if (isAdjacentToCurrentPage(currentPage, page)) {
            shouldDisplay = true;
            displayAsDots = false;
          } else if (page < currentPage && !leftDotsDisplayed) {
            leftDotsDisplayed = true;
            shouldDisplay = true;
            displayAsDots = true;
          } else if (page > currentPage && !rightDotsDisplayed) {
            rightDotsDisplayed = true;
            shouldDisplay = true;
            displayAsDots = true;
          }
        } else {
          shouldDisplay = true;
          displayAsDots = false;
        }

        pages.pushObject(PageObject.create({
          value: page,
          isCurrentPage: isCurrentPage,
          shouldDisplay: shouldDisplay,
          displayAsDots: displayAsDots
        }));
      });

      return pages;
    }),

    actions: {
      setCurrentPage: function setCurrentPage(pageNumber) {
        this.set('currentPage', pageNumber);
        if (this.attrs.setCurrentPage) {
          this.attrs.setCurrentPage(this.get('currentPage'));
        }
      },

      nextPage: function nextPage() {
        var currentPage = this.get('currentPage');
        this.set('currentPage', currentPage + 1);
        if (this.attrs.setCurrentPage) {
          this.attrs.setCurrentPage(this.get('currentPage'));
        }
      },

      previousPage: function previousPage() {
        var currentPage = this.get('currentPage');
        this.set('currentPage', currentPage - 1);
        if (this.attrs.setCurrentPage) {
          this.attrs.setCurrentPage(this.get('currentPage'));
        }
      }
    }
  });

  // TODO: use Set here when time is right, apparently it's not widely supported yet
  function extractPageValues(outerWindow, currentPage, pageStartAtValue, pageEndAtValue) {
    var pageValues = [];
    pageValues.push(pageStartAtValue);
    pageValues.push(pageEndAtValue);

    var addPageValue = function addPageValue(page) {
      pageValues.push(page);
    };

    if (outerWindow > 0) {
      var leftOuterWindowStartAtValue = pageStartAtValue + 1;
      var leftOuterWindowEndAtValue = pageStartAtValue + outerWindow;

      var rightOuterWindowStartAtValue = pageEndAtValue - 1 - outerWindow;
      var rightOuterWindowEndAtValue = pageEndAtValue - 1;

      createRange(leftOuterWindowStartAtValue, leftOuterWindowEndAtValue).forEach(addPageValue);
      createRange(rightOuterWindowStartAtValue, rightOuterWindowEndAtValue).forEach(addPageValue);
    }

    // add / subtract 2 for the dots display for the inner window
    // to handle exactly this use case (if 100 is current page): ... 99 100 101 ...
    var innerWindowStartAtValue = currentPage - 2;
    var innerWindowEndAtValue = currentPage + 2;
    createRange(innerWindowStartAtValue, innerWindowEndAtValue).forEach(addPageValue);

    var takeUnique = function takeUnique(page, idx, array) {
      return array.indexOf(page) === idx;
    };

    return pageValues.sort(function (a, b) {
      return a - b;
    }).filter(function (page) {
      return page >= pageStartAtValue && page <= pageEndAtValue;
    }).filter(takeUnique);
  }

  function shouldConsiderOuterWindowSettings(totalPages, outerWindow) {
    var firstPageContribution = 1;
    var lastPageContribution = 1;
    var extraCurrentPageContribution = 1;

    return totalPages > outerWindow * 2 + firstPageContribution + lastPageContribution + extraCurrentPageContribution;
  }

  function isAdjacentToCurrentPage(currentPage, page) {
    return page === currentPage - 1 || page === currentPage + 1;
  }

  function isWithinOuterWindowLimit(outerWindow, totalPages, page) {
    return isWithinOuterWindowLimitFromLeft(outerWindow, page) || isWithinOuterWindowLimitFromRight(outerWindow, totalPages, page);
  }

  function isWithinOuterWindowLimitFromLeft(outerWindow, page) {
    return page <= outerWindow + 1;
  }

  function isWithinOuterWindowLimitFromRight(outerWindow, totalPages, page) {
    return totalPages - page <= outerWindow;
  }

  function createRange(start, end) {
    var range = [];
    for (var i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  }
});