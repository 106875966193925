define('ornio-nav-app/components/bs/subnav-container', ['exports', 'ember', 'ornio-nav-app/templates/components/bs/subnav-container', 'ember-computed', 'ember-metal/get'], function (exports, _ember, _ornioNavAppTemplatesComponentsBsSubnavContainer, _emberComputed, _emberMetalGet) {
    exports['default'] = _ember['default'].Component.extend({
        layout: _ornioNavAppTemplatesComponentsBsSubnavContainer['default'],
        classNames: ['menu'],
        right: _emberComputed['default'].equal('left', false),
        classNameBindings: ['right:right', 'isActive:active', 'small:small'],
        isActive: (0, _emberComputed['default'])('getName', 'name', function () {
            var getName = (0, _emberMetalGet['default'])(this, 'getName');
            var name = (0, _emberMetalGet['default'])(this, 'name');

            if (name == getName) {
                return true;
            } else {
                return false;
            }
        })
    });
});