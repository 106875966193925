define('ornio-nav-app/components/bs/sidenav-overlay', ['exports', 'ember', 'ornio-nav-app/templates/components/bs/sidenav-overlay', 'ember-metal/get', 'ember-metal/set', 'ember-metal/observer', 'ember-service/inject'], function (exports, _ember, _ornioNavAppTemplatesComponentsBsSidenavOverlay, _emberMetalGet, _emberMetalSet, _emberMetalObserver, _emberServiceInject) {
    exports['default'] = _ember['default'].Component.extend({
        layout: _ornioNavAppTemplatesComponentsBsSidenavOverlay['default'],
        media: (0, _emberServiceInject['default'])(),
        menu: (0, _emberServiceInject['default'])('extend-menu'),
        showOverLayer: false,
        open: false,
        classNames: ['nav-overlay'],
        classNameBindings: ['showOverLayer:is-visible'],
        overlayStatusChagned: (0, _emberMetalObserver['default'])('media.isXl', 'media.isXs', 'media.isLg', 'media.isMd', 'media.isSm', 'overlay', function () {
            var media = (0, _emberMetalGet['default'])(this, 'media');
            var overlay = (0, _emberMetalGet['default'])(this, 'overlay');

            if ((media.get('isLg') || media.get('isMd')) && overlay) {
                (0, _emberMetalSet['default'])(this, 'showOverLayer', true);
            } else if (overlay !== 'appends' && overlay !== 'navigation' && (0, _emberMetalGet['default'])(this, 'open')) {
                (0, _emberMetalSet['default'])(this, 'showOverLayer', true);
            } else {
                (0, _emberMetalSet['default'])(this, 'showOverLayer', false);
            }
        }),
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            var media = (0, _emberMetalGet['default'])(this, 'media');
            var overlay = (0, _emberMetalGet['default'])(this, 'overlay');

            if ((media.get('isLg') || media.get('isMd')) && overlay) {
                (0, _emberMetalSet['default'])(this, 'showOverLayer', true);
            } else {
                (0, _emberMetalSet['default'])(this, 'showOverLayer', false);
            }
        },
        click: function click() {
            var overlay = (0, _emberMetalGet['default'])(this, 'overlay');
            var media = (0, _emberMetalGet['default'])(this, 'media');
            var menu = (0, _emberMetalGet['default'])(this, 'menu.menu');
            var extendMenu = (0, _emberMetalGet['default'])(menu, 'extendMenu');

            if ((media.get('isLg') || media.get('isMd')) && (overlay === 'navigation' || overlay === 'appends')) {
                this.sendAction("closeMenu");
            } else {
                if (extendMenu) {
                    this.sendAction("showMenu", 'appends');
                } else {
                    this.sendAction("showMenu", 'navigation');
                }
            }
        }

    });
});