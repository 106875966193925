define('ornio-auth-engine/components/auth/login-form/validation', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({ type: 'email' })],
        password: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 6 })]
    };
});