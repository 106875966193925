define('ornio-auth-engine/components/auth/login-form-account/component', ['exports', 'ornio-auth-engine/components/auth/login-form-account/template', 'ornio-auth-engine/components/auth/login-form-account/validation', 'ornio-auth-engine/components/auth/login-form-account/schema', 'ornio-auth-engine/auth/error-object-parser'], function (exports, _template, _validation, _schema, _errorObjectParser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set;
    var keys = Object.keys;
    exports.default = Ember.Component.extend({
        layout: _template.default,
        validation: _validation.default,
        session: Ember.inject.service(),
        errors: null,

        init: function init() {
            this._super.apply(this, arguments);
            set(this, 'model', _schema.default.create());
        },


        actions: {
            authenticate: function authenticate(changeset) {
                var _this = this;

                var credentials = { identification: get(changeset, 'account'), password: get(changeset, 'password') },
                    authenticator = 'authenticator:jwt',
                    snapshot = changeset.snapshot();

                // Reset errors
                set(this, 'errors', null);

                return changeset.cast(keys(_schema.schema)).validate().then(function () {
                    if (get(changeset, 'isValid')) {

                        get(_this, 'session').authenticate(authenticator, credentials).then(function () {}, function (error) {
                            if (error) {
                                var parsedErrors = _errorObjectParser.default.parse(error, changeset);

                                // Check if this is non attribute error
                                if (parsedErrors && parsedErrors.length > 0) {
                                    set(_this, 'errors', parsedErrors);
                                }
                            }
                        });
                    } else {
                        console.log('isNotValid');
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            }
        }
    });
});