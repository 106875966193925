define('ornio-auth-engine/components/auth/login-form-account/schema', ['exports', 'ornio-auth-engine/auth/default-schema'], function (exports, _defaultSchema) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;
    var schema = exports.schema = {
        account: null,
        password: null
    };

    exports.default = _defaultSchema.default.extend(schema);
});