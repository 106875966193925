'use strict';

(function (w, u, d) {
    var i = function i() {
        i.c(arguments);
    };i.q = [];i.c = function (args) {
        i.q.push(args);
    };var l = function l() {
        var s = d.createElement('script');s.type = 'text/javascript';s.async = true;s.src = 'https://code.upscope.io/xpenEsA7i2.js';var x = d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);
    };if (typeof u !== "function") {
        w.Upscope = i;l();
    }
})(window, window.Upscope, document);

Upscope('init', {
    // Optionally set the user ID below. If you don't have it, it doesn't matter,
    // Upscope will integrate with your live chat system anyway.
    uniqueId: "USER UNIQUE ID",
    // Optionally set the user name or email below (e.g. ["John Smith", "john.smith@acme.com"])
    identities: ["list", "of", "identities", "here"]
});