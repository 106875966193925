define('ornio-nav-app/components/bs/subnav-injected', ['exports', 'ember', 'ornio-nav-app/templates/components/bs/subnav-injected', 'ember-service/inject', 'ember-utils'], function (exports, _ember, _ornioNavAppTemplatesComponentsBsSubnavInjected, _emberServiceInject, _emberUtils) {
    exports['default'] = _ember['default'].Component.extend({
        layout: _ornioNavAppTemplatesComponentsBsSubnavInjected['default'],
        resize: (0, _emberServiceInject['default'])('resize'),
        classNames: ['subnav-injected-content'],
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            var element = this.$();
            var elementId = this.elementId;
            if ((0, _emberUtils.isPresent)(elementId) && (0, _emberUtils.isPresent)(element)) {
                if (element.has('ul')) {
                    var countElement = element.find('li').length;

                    var height = countElement * 64;

                    if (countElement > 0 && window.innerHeight < height) {
                        var newHeight = window.innerHeight - 70;
                        element.find('ul').css({ 'height': newHeight + 'px' });
                    } else {
                        element.find('ul').css({ 'height': 'auto' });
                    }
                }
            }
        },
        didRender: function didRender() {
            var _this = this;

            this._super.apply(this, arguments);

            this.get('resize').on('didResize', function (event) {
                var element = _this.$();
                var elementId = _this.elementId;
                if ((0, _emberUtils.isPresent)(elementId) && (0, _emberUtils.isPresent)(element)) {
                    if (element.has('ul')) {
                        var countElement = element.find('li').length;

                        var height = countElement * 64;

                        if (countElement > 0 && window.innerHeight < height) {
                            var newHeight = window.innerHeight - 70;
                            element.find('ul').css({ 'height': newHeight + 'px' });
                        } else {
                            element.find('ul').css({ 'height': 'auto' });
                        }
                    }
                }
            });
        }
    });
});