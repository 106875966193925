define('ornio-auth-engine/components/auth/reset-form/component', ['exports', 'ornio-auth-engine/components/auth/reset-form/template', 'ornio-auth-engine/components/auth/reset-form/validation', 'ornio-auth-engine/components/auth/reset-form/schema', 'ornio-auth-engine/auth/error-object-parser'], function (exports, _template, _validation, _schema, _errorObjectParser) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get,
        set = Ember.set;
    var keys = Object.keys;
    exports.default = Ember.Component.extend({
        layout: _template.default,
        validation: _validation.default,
        session: Ember.inject.service(),
        ajax: Ember.inject.service(),
        errors: null,
        success: null,

        init: function init() {
            this._super.apply(this, arguments);
            set(this, 'model', _schema.default.create({ token: get(this, 'token') }));
        },


        actions: {
            resetPassword: function resetPassword(changeset) {
                var _this = this;

                var snapshot = changeset.snapshot();

                // Reset errors & success variable
                set(this, 'errors', null);
                set(this, 'success', null);

                return changeset.cast(keys(_schema.schema)).validate().then(function () {
                    if (get(changeset, 'isValid')) {
                        changeset.save().then(function (_ref) {
                            var payload = _ref.payload;

                            set(_this, 'model', _schema.default.create());
                            set(_this, 'success', payload);
                        }).catch(function (_ref2) {
                            var payload = _ref2.payload;

                            var parsedErrors = _errorObjectParser.default.parse(payload, changeset);

                            // Check if this is non attribute error
                            if (parsedErrors && parsedErrors.length > 0) {
                                set(_this, 'errors', parsedErrors);
                            }
                        });
                    } else {
                        console.log('isNotValid');
                    }
                }).catch(function () {
                    changeset.restore(snapshot);
                });
            }
        }
    });
});