define('ornio-auth-engine/components/bs-alert/component', ['exports', 'ornio-auth-engine/components/bs-alert/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['alert', 'fade', 'show'],
        classNameBindings: ['type', 'isDismissible:alert-dismissible'],
        type: 'alert-info',
        isDismissible: false,
        layout: _template.default
    });
});