define('ornio-nav-app/services/extend-menu', ['exports', 'ember', 'ember-metal/get', 'ember-metal/set'], function (exports, _ember, _emberMetalGet, _emberMetalSet) {
    exports['default'] = _ember['default'].Service.extend({
        menu: '',
        open: true,
        hidden: false,
        url: '',
        setMenu: function setMenu(menu) {
            (0, _emberMetalSet['default'])(this, 'menu', menu);
        },
        getMenu: function getMenu() {
            return (0, _emberMetalGet['default'])(this, 'menu');
        }
    });
});