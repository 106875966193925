define('ornio-auth-engine/components/display-jsonapi-errors/component', ['exports', 'ornio-auth-engine/components/display-jsonapi-errors/template'], function (exports, _template) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['ornio-auth-alert'],
        layout: _template.default
    });
});