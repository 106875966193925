define('ornio-auth-engine/components/auth/confirm-resend-form/schema', ['exports', 'ornio-auth-engine/auth/default-schema', 'ember-ajax/raw', 'ember-get-config'], function (exports, _defaultSchema, _raw, _emberGetConfig) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;
    var schema = exports.schema = {
        email: null,

        save: function save() {
            var payload = {
                "data": {
                    "attributes": {
                        "email": Ember.get(this, 'email')
                    },
                    "type": 'auth-resend'
                }
            };

            var options = {
                method: 'POST',
                contentType: 'application/vnd.api+json',
                data: JSON.stringify(payload)
            };

            return (0, _raw.default)(_emberGetConfig.default.APP.API_AUTH_CONFIRM_RESEND, options);
        }
    };

    exports.default = _defaultSchema.default.extend(schema);
});