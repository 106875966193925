define("ember-initials/mixins/initials/-private/utils", ["exports"], function (exports) {
  exports.colorIndex = colorIndex;
  function hashCode(string) {
    var hash = 0;

    if (string && string.length > 0) {
      for (var i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }
    }

    return hash & hash;
  }

  function colorIndex(seedText, colorsLength) {
    var code = hashCode(seedText);
    return Math.abs(Math.floor(code % colorsLength));
  }
});