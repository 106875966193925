define('ember-initials/config', ['exports'], function (exports) {
  exports['default'] = {
    image: {
      defaultImageUrl: ''
    },

    gravatar: {
      defaultImageUrl: ''
    }
  };
});