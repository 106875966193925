define('ornio-auth-engine/auth/error-object-parser', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.parse = parse;


    var SOURCE_POINTER_REGEXP = /^\/?data\/(attributes|relationships)\/(.*)/;
    var SOURCE_POINTER_PRIMARY_REGEXP = /^\/?data/;
    var PRIMARY_ATTRIBUTE_KEY = 'base';

    function parse(payload, changeset) {
        var errors = Ember.get(payload, 'errors');
        var out = {};

        // No errors
        if (!errors) {
            return false;
        }

        // Check if its exists
        if (Ember.isPresent(errors)) {
            // Wrap it as array
            errors = Array.isArray(errors) === true ? errors : [errors];

            // Run throug it
            errors.forEach(function (error) {
                if (error.source && error.source.pointer) {
                    var key = error.source.pointer.match(SOURCE_POINTER_REGEXP);

                    if (key) {
                        key = key[2];
                    } else if (error.source.pointer.search(SOURCE_POINTER_PRIMARY_REGEXP) !== -1) {
                        key = PRIMARY_ATTRIBUTE_KEY;
                    }

                    if (key) {
                        out[key] = out[key] || [];
                        out[key].push(error.detail || error.title);

                        if (Ember.isPresent(changeset)) {
                            var message = Ember.isPresent(error.detail) ? error.detail : error.title;
                            changeset.pushErrors(key, message);
                        }
                    }
                }
            });
        }

        if (Ember.isPresent(changeset) && Object.keys(out).length > 0) {
            return true;
        }

        return errors;
    }
});