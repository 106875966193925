define('ember-initials/mixins/gravatar', ['exports', 'ember', 'ember-initials/config', 'md5'], function (exports, _ember, _emberInitialsConfig, _md5) {
  exports['default'] = _ember['default'].Mixin.create({
    tagName: 'img',
    attributeBindings: ['width', 'height', 'src'],

    email: null,

    image: null,
    relativeUrl: false,
    defaultImage: _ember['default'].computed(function () {
      return this.get('config.gravatar.defaultImage');
    }),

    size: 30,
    height: _ember['default'].computed.reads('size'),
    width: _ember['default'].computed.reads('size'),

    src: _ember['default'].computed('email', 'size', 'image', 'defaultImage', 'fastboot.isFastBoot', function () {
      return this.get('fastboot.isFastBoot') || this.get('image') ? this.get('image') : this.generateGravatarUrl();
    }),

    config: _ember['default'].computed(function () {
      var appSettings = _ember['default'].getOwner(this).resolveRegistration('config:environment').emberInitials || {};
      return _ember['default'].assign(_emberInitialsConfig['default'], appSettings);
    }),

    fastboot: _ember['default'].computed(function () {
      return _ember['default'].getOwner(this).lookup('service:fastboot');
    }),

    generateGravatarUrl: function generateGravatarUrl() {
      var hash = (0, _md5['default'])(this.get('email'));
      var size = this.get('size');
      var defaultImage = this.defaultImageUrl();
      var image = defaultImage ? '&default=' + defaultImage : '';

      return '//www.gravatar.com/avatar/' + hash + '?size=' + size + image;
    },

    defaultImageUrl: function defaultImageUrl() {
      if (this.get('relativeUrl') && this.get('defaultImage')) {
        return window.location.origin + '/' + this.get('defaultImage');
      } else {
        return this.get('defaultImage');
      }
    }
  });
});