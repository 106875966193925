define('ornio-auth-engine/components/auth/reset-form/schema', ['exports', 'ornio-auth-engine/auth/default-schema', 'ember-ajax/raw', 'ember-get-config'], function (exports, _defaultSchema, _raw, _emberGetConfig) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.schema = undefined;
    var schema = exports.schema = {
        token: null,
        password: null,
        passwordConfirmation: null,

        save: function save() {

            var attr = {
                token: Ember.get(this, 'token'),
                password: Ember.get(this, 'password'),
                password_confirmation: Ember.get(this, 'passwordConfirmation')
            };

            var payload = {
                "data": {
                    "attributes": attr,
                    "type": 'auth-reset'
                }
            };

            var options = {
                method: 'POST',
                contentType: 'application/vnd.api+json',
                data: JSON.stringify(payload)
            };

            return (0, _raw.default)(_emberGetConfig.default.APP.API_AUTH_RESET, options);
        }
    };

    exports.default = _defaultSchema.default.extend(schema);
});